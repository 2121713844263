import { ClickedInviteMember, useTrackClickedInviteMember } from '@air/analytics';
import { Banner } from '@air/primitive-banner';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { TabPanel, Tabs } from '@air/primitive-tabs';
import { useCallback, useMemo, useState } from 'react';
import { useMount } from 'react-use';

import { MemberLimitMiniBanner } from '~/components/MemberLimitMiniBanner';
import { InviteByEmail } from '~/components/Modals/InviteMembers/InviteMembersModal/InviteByEmail';
import { InviteByLink } from '~/components/Modals/InviteMembers/InviteMembersModal/InviteByLink';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { EnhancedSubscription, useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { canAddWorkspaceMembers, canCreateWorkspaceInviteLink } from '~/utils/permissions/workspacePermissions';

export type InviteMembersModalProps = ClickedInviteMember;

const SubscriptionIssue = ({
  title = 'Subscription Issue',
  subtitle = "There's an issue with your subscription. Please contact support to resolve.",
}) => {
  return (
    <Banner data-testid="SUBSCRIPTION_ISSUE_BANNER" appearance="warning" className="mb-4">
      <p className="font-semibold">{title}</p>
      <p>{subtitle}</p>
    </Banner>
  );
};

const getSubscriptionIssueInfo = (subscription?: EnhancedSubscription) => {
  if (!subscription) return {};
  switch (subscription.expirationReason) {
    case 'payment_failed':
      return {
        subscriptionIssueTitle: 'Payment failed',
        subscriptionIssueMessage:
          'Your subscription is paused due to a failed payment. Visit your workspace settings or contact an admin to resolve.',
      };
    case 'subscription_cancelled':
      return {
        subscriptionIssueTitle: 'Subscription expired',
        subscriptionIssueMessage: 'Your subscription has expired. Please contact support to re-subscribe.',
      };
    default:
      return {
        subscriptionIssueTitle: 'Subscription issue',
        subscriptionIssueMessage: "There's an issue with your subscription. Please contact support to resolve.",
      };
  }
};

export const InviteMembersModal = ({ onClose, location }: AirModalProps<InviteMembersModalProps>) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const defaultTab = sessionStorage.getItem('inviteModalTab')?.toString() || 'invite-by-email';
  const { trackClickedInviteMember } = useTrackClickedInviteMember();
  const { data: subscription } = useSubscriptionContext();
  const hasSubscriptionIssue = !!subscription?.expirationReason;
  const { subscriptionIssueTitle, subscriptionIssueMessage } = getSubscriptionIssueInfo(subscription);
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canAddMembers = canAddWorkspaceMembers(permissions);
  const canCreateInviteLink = canCreateWorkspaceInviteLink(permissions);
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  useMount(() => {
    trackClickedInviteMember({ location });
  });

  const panels = useMemo<TabPanel[]>(
    () =>
      currentWorkspace?.name
        ? [
            {
              id: 'invite-by-email',
              label: 'Invite by email',
              content: <InviteByEmail location={location} workspaceTitle={currentWorkspace?.name} />,
            },
            {
              id: 'invite-by-link',
              label: 'Invite by link',
              content: <InviteByLink workspaceTitle={currentWorkspace.name} />,
            },
          ]
        : [],
    [currentWorkspace?.name, location],
  );

  const onTabChange = useCallback((value: string) => {
    sessionStorage.setItem('inviteModalTab', value);
    setActiveTab(value);
  }, []);

  if (!currentWorkspace || (!canAddMembers && !canCreateInviteLink)) {
    return null;
  }

  return (
    <Modal isOpen onDismiss={onClose} dangerouslyBypassFocusLock>
      <header className="mb-6 flex items-center justify-between gap-2">
        <ModalTitle>Invite members</ModalTitle>
        <ModalCloseButton onClick={onClose} />
      </header>

      {hasSubscriptionIssue && <SubscriptionIssue title={subscriptionIssueTitle} subtitle={subscriptionIssueMessage} />}
      <MemberLimitMiniBanner>You&apos;ve reached your member limit.</MemberLimitMiniBanner>
      <Tabs
        className="-mx-6 -mt-1"
        defaultValue={defaultTab}
        listClassName="px-6"
        panels={panels}
        panelsClassName="px-8"
        onValueChange={onTabChange}
        value={activeTab}
        data-testid="INVITE_MEMBERS_TABS"
      />
    </Modal>
  );
};
